import { PckFilters } from 'types/filters.type';

export const pckFilters: Array<PckFilters> = [
    {
        id: 'platform',
        name: 'Platform',
        options: [
            {
                value: 'open-telemetry-compliant',
                label: 'OpenTelemetry',
            },
            {
                value: 'bmc-helix-op',
                label: 'Helix Operations Management'
            },
            {
                value: 'bmc-helix-co',
                label: 'TrueSight Capacity Optimization'
            }
        ],
    },
    {
        id: 'solution',
        name: 'Solution',
        options: [
            {
                value: 'hardware-monitoring',
                label: 'Hardware Monitoring'
            },
            {
                value: 'storage-monitoring',
                label: 'Storage Monitoring'
            },
            {
                value: 'application-monitoring',
                label: 'Application Monitoring'
            },
            {
                value: 'high-availability-optimization',
                label: 'High-Availability Monitoring'
            },
            {
                value: 'backup-monitoring',
                label: 'Backup Monitoring'
            },
            {
                value: 'capacity-optimization',
                label: 'Capacity Optimization'
            },
        ],
    },
    {
        id: 'other',
        name: 'Other',
        options: [
            {
                value: 'bmc-installations-utility',
                label: 'BMC Installation Utility'
            },
            {
                value: 'jre',
                label: 'JRE'
            },
            {
                value: 'troubleshooting-tools',
                label: 'Troubleshooting Tools'
            }
        ],
    },
];